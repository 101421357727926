import Flickity from '../../node_modules/flickity'

const articleSlideshow = () => {
  const slideshowCount = document.getElementsByClassName('article-slideshow--count'),
        slideshow      = document.getElementsByClassName('article-slideshow')

  if (slideshow.length > 0) {
    let flkty = new Flickity('.article-slideshow', {
      cellAlign: 'left',
      wrapAround: true,
      pageDots: false,
      resize: true,
      imagesLoaded: true,
      setGallerySize: true
    })

    let updateStatus = () => {
      const cellNumber = flkty.selectedIndex + 1

      Array.from(slideshowCount).forEach((e, i) => {
        e.innerHTML = cellNumber + '/' + flkty.slides.length
      })
    }

    updateStatus()
    flkty.on('select', updateStatus)

    window.addEventListener( 'load', function() {
      flkty.resize();
    });
  }
}

export default articleSlideshow
