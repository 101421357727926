import * as helpers from './Helpers'

const settings = {
  toggleClass: helpers.toggleClass,
  addListenerMulti: helpers.addListenerMulti
}

const s = settings

const initMobileMenu = () => {
  MobileMenu()
}

const MobileMenu = () => {
  const mobileMenuToggle = document.getElementsByClassName('js-mobileMenuToggle')[0],
        body = document.getElementsByTagName('body')[0],
        toggleClass = s.toggleClass

  mobileMenuToggle.addEventListener('mousedown', () => {
    toggleClass(body, 'js-isMenuPress')
  })
  mobileMenuToggle.addEventListener('mouseup', () => {
    toggleClass(body, 'js-isMenuPress')
  })
  mobileMenuToggle.addEventListener('touchstart', () => {
    toggleClass(body, 'js-isMenuPress')
  })
  mobileMenuToggle.addEventListener('touchend', () => {
    toggleClass(body, 'js-isMenuPress')
  })

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 769 && body.classList.contains('js-notMenuShown')) {
      body.classList.remove('js-notMenuShown')
    } else if (window.innerWidth >= 769 && body.classList.contains('js-isMenuShown')) {
      body.classList.remove('js-isMenuShown')
    }
  })

  mobileMenuToggle.addEventListener('click', () => {
    if (body.classList.contains('js-isMenuShown')) {
      body.classList.remove('js-isMenuShown')
      body.classList.add('js-notMenuShown')
    } else {
      body.classList.add('js-isMenuShown')
      body.classList.remove('js-notMenuShown')
    }
  })
}

export default initMobileMenu
