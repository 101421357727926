import * as helpers from './Helpers'

const settings = {
  toggleClass: helpers.toggleClass 
}

const footNotes = () => {

  // Round up footnote and footer elements
  const footNoteText = document.getElementsByTagName('sup')
  const footNoteMarker = document.getElementsByTagName('sup')
  const footNoteFooter = document.querySelector('.article-footnotes ol')

  // If there are footnotes, manipulate them
  if ( footNoteText.length > 0 ) {
  
    Array.from(footNoteMarker).forEach((e, i) => {

      // Wrap <sup> contents in a <p> tag and prepend a jump link
      let footNoteStripped = footNoteText[i].innerHTML.replace(/<[^>]*>|↩/g,  "")
      let footNoteReformatted = '<p>' + footNoteStripped + '</p>'
      let footNoteReference = document.createElement('a')

      footNoteReference.classList.add('footnote-reference')
      footNoteReference.id = `fnref-${i+1}`
      footNoteReference.href = `#fn-${i+1}`
      footNoteReference.innerHTML = i + 1
      
      e.innerHTML = ''
      e.appendChild(footNoteReference)
      e.insertAdjacentHTML('beforeend', footNoteReformatted)

      // Put a copy of each footnote in the article footer with a link back to its marker
      let footerFootNote = `
        <li class="footnote" id="fn-${i+1}">
          <p>
            ${footNoteStripped}
            <a title="Return to article" href="#fnref-${i+1}">&larrhk;</a>
        </li>
      `
      footNoteFooter.innerHTML += footerFootNote
    })
  } else return
}

export default footNotes
